import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import Header from "../components/header";
import SearchResults from "../components/searchResults";
import Footer from "../components/footer";
import SeO from "../components/SEO";
import NotFoundPage from "./404";

import settings from "../components/settings.json";
const axios = require("axios");

const SearchFormTemplate = (props) => {
  const slug = props.slug;
  const city = props.citystate?.split(",")[0];
  const state = props.citystate?.split(",")[1];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  var stateData = props.location.state || {};
  const [pageTitle, setPageTitle] = useState(stateData.name || props.slug);
  const cityForm = city
    ? city
    : localStorage.getItem("new_city_name") !== null
    ? localStorage.getItem("new_city_name")
    : localStorage.getItem("city_name");
  const statecode = state
    ? state
    : localStorage.getItem("new_state_code") !== null
    ? localStorage.getItem("new_state_code")
    : localStorage.getItem("state_code");

  useEffect(() => {
    const getCategoryResults = () => {
      if (statecode) {
        var location = cityForm.split(",")[0];
        axios({
          url: settings.apiURL,
          method: "post",
          data: {
            query: `{
              searchByCatOrBusinessSlug(slug: "${slug}", location: "${location}", state_code: "${statecode}"){
                  id
                  others
                  suggestiontext
                  suggestionrelated
                  suggestionurls
                  status
                  certified
                  name
                  phone
                  address
                  city
                  state
                  zip
                  message
                  referred_by
                  category
                  company_logo
                  latitude
                  statusmessage
                  longitude
                  website
                  facebook
                  instagram
                  youtube
                  twitter
                  description
                  service_frequency
                  days_open
                  email
                  images{
                      id
                      path
                  }
                  company_coupon
                  company_ad
                  advertise_begin_date
                  review_link
                  spotlight_link
                  audio
                  video
                  category
                  wal_pdf
                  keywords
                  distance
                  linkedin
                  show_address
                  show_ad
                  company_ad
                  slug
                }
              }`,
          },
        })
          .then((res) => {
            if (res.data.data.searchByCatOrBusinessSlug.length) {
              setData(res.data.data.searchByCatOrBusinessSlug);
              setPageTitle(
                res.data.data.searchByCatOrBusinessSlug[0]["suggestiontext"]
              );
            } else {
              setData([]);
              setPageTitle("Not Found");
            }
            setTimeout(() => {
              setLoading(false);
            }, 500);
            return "success";
          })
          .catch((err) => {
            setData([]);
            setPageTitle("Not Found");
            setLoading(false);
            console.log(err.message);
          });
      } else {
        axios({
          url: settings.apiURL,
          method: "post",
          data: {
            query: `{
              searchByCatOrBusinessSlug(slug: "${slug}"){
                  id
                  others
                  suggestiontext
                  suggestionrelated
                  suggestionurls
                  status
                  certified
                  name
                  phone
                  address
                  city
                  state
                  zip
                  message
                  referred_by
                  category
                  company_logo
                  latitude
                  statusmessage
                  longitude
                  website
                  facebook
                  instagram
                  youtube
                  twitter
                  description
                  service_frequency
                  days_open
                  email
                  images{
                      id
                      path
                  }
                  company_coupon
                  company_ad
                  advertise_begin_date
                  review_link
                  spotlight_link
                  audio
                  video
                  category
                  wal_pdf
                  keywords
                  distance
                  linkedin
                  show_address
                  show_ad
                  company_ad
                  slug
                }
              }`,
          },
        })
          .then((res) => {
            if (res.data.data.searchByCatOrBusinessSlug.length) {
              setData(res.data.data.searchByCatOrBusinessSlug);
              setPageTitle(
                res.data.data.searchByCatOrBusinessSlug[0]["suggestiontext"]
              );
            } else {
              setData([]);
              setPageTitle("Not Found");
            }
            setTimeout(() => {
              setLoading(false);
            }, 500);
            return "success";
          })
          .catch((err) => {
            setData([]);
            setPageTitle("Not Found");
            setLoading(false);
            console.log(err.message);
          });
      }
    };

    let ignore = false;
    if (!ignore) {
      setLoading(true);
      ignore = true;
      setTimeout(() => {
        getCategoryResults();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, cityForm]);

  if (cityForm) {
    return (
      <div>
        <SeO title={pageTitle} />
        <Header
          path={props.path.split("/")[1]}
          city={props.citystate}
          searchSlug={props.slug}
        />
        <SearchResults
          location={cityForm}
          slug={props?.slug}
          name={
            stateData.name?.replaceAll("-", " ") ||
            props?.slug.replaceAll("-", " ")
          }
          loading={loading}
          data={data}
        />
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <SeO title={pageTitle} />
        <Header
          path={props.path.split("/")[1]}
          city={props.citystate}
          searchSlug={props.slug}
        />
        <SearchResults
          location={cityForm}
          slug={props?.slug}
          name={
            stateData?.name.replaceAll("-", " ") ||
            props?.slug.replaceAll("-", " ")
          }
          loading={loading}
          data={data}
        />
        <Footer />
      </div>
    );
  }
};

const search = ({ pageContext }) => {
  return (
    <Router>
      <SearchFormTemplate
        path="/search/:slug/:citystate"
        context={pageContext}
        exact
      />
      <SearchFormTemplate path="/search/:slug" context={pageContext} />
      <NotFoundPage path="/search/*" />
    </Router>
  );
};
export default search;
